import React from "react";
import loadable from "@loadable/component";
import Layout from "../../components/Layout/layout";
import SEO from "../../components/seo";
import {
  southCarolinaHero,
  southCarolinaRestaurantTypes,
  southCarolinaIconTemplate,
  southCarolinaLargeFeatures,
  southCarolinaWhiteGlove,
  southCarolinaXL,
  southCarolinaTestimonials,
  southCarolinaSeal,
} from "../../data/local/south-carolina-data";
import ogImage from "../../images/global_assets/og-image.png";
import LocalHero from "../../components/Hero/LocalHero";
import LocalSlider from "../../components/ColoradoPortedComps/LocalSlider";
import PartnerLogos from "../../components-v2/Sections/PartnerLogos";

const LargeFeatures = loadable(() =>
  import("../../components/LargeFeatures/LargeFeatures")
);
const WhiteGlove = loadable(() =>
  import("../../components/WhiteGlove/WhiteGlove")
);
const LocalRestaurants = loadable(() =>
  import("../../components/ColoradoPortedComps/LocalRest")
);
const Restaurants = loadable(() =>
  import("../../components/ColoradoPortedComps/Rest")
);
const XLArea = loadable(() =>
  import("../../components/ColoradoPortedComps/XLArea")
);
// const TestmonialReviews = loadable(() =>
//   import("../../components/TestimonialReviews/TestmonialReviews")
// );
const whiteGloveImport = "caregiver.png";

const SouthCarolina = () => {
  return (
    <Layout
      isLanding
      secondaryNav
      phoneNumber="+18882246506"
      phoneText="(888) 224-6506"
    >
      <SEO
        title="SpotOn | South Carolina Restaurant Association"
        description="SpotOn is empowering South Carolina restaurants and businesses with top-rated point-of-sale systems and software, backed by in-person local support and fair pricing"
        image={`https://spoton.com/${ogImage}`}
      />
      <LocalHero sectionData={southCarolinaHero} seal={southCarolinaSeal} />
      <PartnerLogos />
      <LocalRestaurants
        data={southCarolinaRestaurantTypes}
        title="Proudly serving South Carolina restaurants of all types"
        nav="#"
      />
      <Restaurants
        data={southCarolinaIconTemplate}
        nav="pos"
        title="SpotOn Restaurant POS"
        subtext="More than a point-of-sale. A better way to run your restaurant, with cloud-based tools that work hand-in-hand to save you time, increase sales, get better data and manage through the labor shortage."
      />
      <LocalSlider
        nav="cust"
        title="What other restaurants have to say"
        testimonialsData={southCarolinaTestimonials}
      />
      <LargeFeatures
        sectionData={southCarolinaLargeFeatures}
        style={{ marginTop: "128px" }}
      />
      <XLArea data={southCarolinaXL} nav="service" />
      <WhiteGlove
        sectionData={southCarolinaWhiteGlove}
        whiteGloveBg={whiteGloveImport}
      />
    </Layout>
  );
};

export default SouthCarolina;
