// eslint-disable-next-line import/prefer-default-export
export const southCarolinaHero = {
  title: "Point-of-sale is just the beginning",
  subtext:
    "As an association member, you can choose how you want to save with SpotOn. Either get a credit towards your first POS station or receive a free consultation, set up, and trial of SpotOn Reserve or SpotОn's labor management solution. Click to schedule a demo to learn more.",
  heroImg: "South Carolina.png",
  imageClassName: "fixed-height-700 top-adjustement-b",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/associations/demo",
  },
  video: {
    videoBtn: "Play video",
    posterWrapperClass: "",
    posterImg: "hero-video-image.png",
    videoUrlId: "hLlIUkFESiw",
  },
};

export const southCarolinaSeal = {
  title: "South Carolina Restaurant and Lodging Association:",
  img: "SCRLA.png",
  text: "SpotOn Named the Preferred Technology Partner of the South Carolina Restaurant and Lodging Association",
  url: "/blog/south-carolina-restaurant-lodging-association/",
};

export const southCarolinaLargeFeatures = {
  featureBlocks: [
    {
      blockTitle: "Online ordering, delivery & dine-in ordering",
      blockImg: "large-phone.png",
      blockSubTitle:
        "Forget about third-party apps. SpotOn’s digital solutions for takeout, delivery, and on-premise dining not only help you increase throughput and boost revenue, but also save you money.",
      blockList: [
        "Guests order directly from you—no app needed",
        "QR dine-in ordering to speed up service",
        "SpotOn Delivery, powered by DoorDash",
        "Order with Google integration to reach more guests",
        "Full POS integration with pacing controls",
        "Own your guest data",
      ],
      id: "oo",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/associations/demo",
      },
    },
    {
      blockTitle: "Waitlisting & reservations",
      blockSubTitle:
        "Make sure your guests know they have a table waiting for them and streamline your front-of-house operations—with no per-cover fees ever.",
      blockImg: "reservation_confirmed.png",
      blockList: [
        "24-hour online booking",
        "Waitlisting & real-time notifications",
        "Server & table management",
        "Reserve with Google listing",
        "Optional vaccination status checkbox",
        "POS integration or standalone front-of-house",
      ],
      id: "rct",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/associations/demo",
      },
    },
    {
      blockTitle: "Contactless payments & ordering",
      blockImg: "contactless-payments.png",
      blockSubTitle:
        "Give guests what they want with more ways to order and pay, while reducing person-to-person contact and empowering your staff to serve more guests, faster.",
      blockList: [
        "Handheld POS",
        "QR code on receipt",
        "Credit card terminal",
        "Customer-facing displays",
        "Mobile dine-in ordering",
        "Mobile card readers",
      ],
      id: "cp",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/associations/demo",
      },
    },
    {
      blockTitle: "Digital marketing & loyalty",
      blockImg: "Marketing.png",
      blockSubTitle:
        "Grow your business by connecting with our customers online, in-person, and on their mobile phones. SpotOn’s built-in tools make it easy to attract new customers and keep your regulars coming back.",
      blockList: [
        "Marketing campaigns",
        "Loyalty rewards",
        "Online review management",
        "Web dashboard",
        "Mobile dashboard app",
        "Guest and campaign data & insights",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/associations/demo",
      },
    },
  ],
};
export const southCarolinaRestaurantTypes = [
  {
    content: "Casual Full-Service",
    desc: "Turn more tables and create a top-notch experience that lets guests order and pay their way.",
    img: "casual_fs.png",
  },
  {
    content: "Bars & Nighclubs",
    desc: "Streamline operations and monitor bar stations remotely with top-shelf tech.",
    img: "bars_clubs.png",
  },
  {
    content: "Quick Service",
    desc: "Accelerate the checkout process with built-in deal redemptions and loyalty rewards to drive repeat visits.",
    img: "quick-serve.png",
  },
  {
    content: "Enterprise",
    desc: "Manage menus and labor across multiple location, with real-time customer insights and data.",
    img: "enterprise.png",
  },
  {
    content: "Pizzeria",
    desc: "Simplify operations, including delivery, while giving your customers multiple ways to order and pay.",
    img: "pizzeria.png",
  },
  {
    content: "Fine Dining",
    desc: "Create a VIP-level experience and boost efficiency with integrated reservations, waitlisting, and point-of-sale.",
    img: "fine-dine.png",
  },
];

export const southCarolinaTestimonials = [
  {
    testimonial: `“SpotOn’s local support has always been super responsive and made the transition from our outdated POS simple and easy”`,
    client: ``,
    company: `Miss Goggi`,
    imageOne: `mgoggi2.png`,
    imageTwo: `mgoggi1.png`,
    imageThree: `mgoggi3.png`,
  },
  {
    testimonial: `“I can't say enough good about this company and software. Ease of Use and Integration are "SpotOn", and the customer service is a true breath of fresh air!!!”`,
    client: ``,
    company: `Riley's`,
    imageOne: `rileys1.png`,
    imageTwo: `rileys3.png`,
    imageThree: `rileys2.jpeg`,
  },
  {
    testimonial: `“SpotОn is the best point of sale system that I have ever worked with. I have worked with many different systems and I can say SpotОn has all you need for a restaurant.”`,
    client: `Joseph Lozada `,
    company: `El Don Mexican`,
    imageOne: `el_don1.png`,
    imageTwo: `el_don2.png`,
    imageThree: `el_don3.png`,
  },
];

export const southCarolinaIconTemplate = [
  {
    content: "Front-of-house",
    desc: "Create an unforgettable guest experience & streamline operations",
    img: "book-open.png",
  },
  {
    content: "Back-of-house",
    desc: "Update menus, pricing specials, and team members in real-time",
    img: "urgent.png",
  },
  {
    content: "Handheld POS & mobile ordering",
    desc: "Ditch the wires to better serve guests and turn tables faster",
    img: "calc.png",
  },
  {
    content: "Real-time guest data & reporting",
    desc: "Get the insights you need to adapt quickly, including right from your phone",
    img: "pie-chart.png",
  },
  {
    content: "Third-party integrations",
    desc: "Connect your POS to the other software and tools you rely on",
    img: "git-merge.png",
  },
  {
    content: "Face-to-face installation & training",
    desc: "Get your staff started right with help from real-life human experts",
    img: "award.png",
  },
  {
    content: "Online ordering",
    desc: "If you’re looking for quick, easy order and pay options that are a cut above your competitors, SponOn’s point-of-sale solutions can deliver all that plus loyalty programs that work",
    img: "online-purchase.png",
  },
  {
    content: "Reservations",
    desc: "Easily manage your waitlist and reservation system in real time while staying compliant with southCarolina’s contract tracing requirements",
    img: "reservation.png",
  },
];

export const southCarolinaAutoIconTemplate = [
  {
    content: "SpotOn Terminal",
    desc: "Cut the wires, connect with customers, and get paid. Take payments wherever you want, offer cash discounts, print receipts, and drive repeat visits with built-in digital loyalty",
    img: "virtual-terminal.png",
  },
  {
    content: "Online Appointments",
    desc: "Easily manage appointments and team member schedules while allowing your customers to book at any time, right from your website or your Facebook page",
    img: "appointment.png",
  },
  {
    content: "Custom Web Design",
    desc: "Our specialists will build a professional website for you at a do-it-yourself price, so you show up higher in Google searches and attract more customers",
    img: "website.png",
  },
  {
    content: "Integrated marketing tools",
    desc: "Run and grow your business from a single dashboard—online or right from your phone. Manage your payments, loyalty rewards, and appointments, send marketing emails, monitor your online reputation on Yelp and Google, and send invoices",
    img: "marketing.png",
  },
];

export const southCarolinaXL = {
  mainTitle: "Proudly serving businesses across South Carolina",
  subtext:
    "SpotOn is proud to be the Preferred Technology Partner of the South Carolina Restaurant and Lodging Association by providing superior value to the local foodservice and lodging industry.  Our team of local professionals are trusted members of their South Carolina business communities and are here to put your business first.",
  phoneNumber: "+18882246506",
  phoneText: "(888) 224-6506",
  email: "hospitality@spoton.com",
};

export const southCarolinaWhiteGlove = {
  mainTitle: "Industry-leading service & support",
  title: "We’re here. Wherever you need us",
  content:
    "Your business deserves the best professional support you can get. That’s why we provide local service with in-person installation and training, backed by 24/7/365 technical support.",
  contentList: [
    "Face-to-face hardware installation",
    "In-person staff training",
    "Virtual training whenever you need it",
  ],
  ctaInfoPrimary: {
    ctaTitle: "Learn more",
    ctaTarget: "/associations/demo",
  },
};
